<template>
  <div>
    <div>
      <div class="menu-logo-desktop">
        <v-img :src="require('@/assets/jj.png')" width="450px" contain />
        <div>
          <div class="header-menu-link" @click="pageClicked(contactData)">
            <div class="menu-link-text">Contact Johnson &amp; Johnson</div>
            <div class="menu-link-icon">
              <v-img :src="require(`@/assets/contact_icon.png`)" contain />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="retina-menu desktop-menu">
          <div class="menu-wrapper">
            <div class="text-hero">
              <div class="menu-text">Resources in <br/> Retinal Disease</div>
            </div>
          </div>
          <div class="menu-links">
            <div
              v-for="page in pages"
              :key="page.id"
              class="menu-link-wrapper"
              :style="`width: ${100 / pages.length}%`"
              @click="pageClicked(page)"
            >
              <div class="menu-link">
                <div class="menu-link-text" v-html="page.menutitle"></div>
                <img
                  class="menu-link-img"
                  :style="`bottom: ${linkImgBottom}px;`"
                  :src="'icons/' + page.icon"
                  v-if="page.icon"
                />
                <div class="menu-link-icon">
                  <v-icon color="white">mdi-arrow-right</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup :pages="pages"></popup>
  </div>
</template>

<script>
import analytics from "../services/analytics";
export default {
  name: "MenuDesktop",
  props: ["pages"],
  components: {
    Popup: () => import("@/views/Popup.vue")
  },
  data: () => ({
    contactData: {
      id: "request",
      title: "Contact J&amp;J",
      componentName: "Contact",
      color: "#003a70",
      bgimage: "mission-banner.png",
      popup: "requestPopup",
      component: "request"
    },
    linkImgBottom: 100
  }),
  methods: {
    getLinkImgBottom() {
      // console.log("getLinkImgBottom");
      try {
        const el = document.querySelectorAll(".menu-link-wrapper")[0];
        const elHeight = el.offsetHeight;
        this.linkImgBottom = elHeight / 2 - 64;
        // console.log("  success => " + this.linkImgBottom);
      } catch {
        // console.log("  error => 100");
        this.linkImgBottom = 100;
      }
    },
    pageClicked(page) {
      analytics.page(page.id);
      window.$app.checking = true;
      this.$hub.$emit("startScreenSaverCheck");
      if (page.url) {
        window.open(page.url, "_blank");
      } else if (page.popup === "requestPopup") {
        this.$hub.$emit("requestPopup", page);
      } else {
        this.$router.push({ name: page.id });
        this.$hub.$emit("openPopup", page.id);
      }
    },
    returnClicked(url) {
      window.open(url, "_blank");
    }
  },
  mounted() {
    window.$menu = this;
    this.getLinkImgBottom();
    window.onresize = () => {
      this.getLinkImgBottom();
    };
  }
};
</script>

<style lang="scss">
.menu-logo-desktop {
  width: calc(100vw - 20px);
  max-width: 1800px;
  margin: 8px auto;
  text-align: left;
  height: 120px;
  padding-top: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .v-image {
    max-width: 500px;
    max-height: 256px;
  }
}
.header-menu-link {
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .menu-link-icon {
    width: 24px;
    display: inline-block;
  }
  .menu-link-text {
    font-family: "JohnsonText-Medium", Arial, Helvetica, sans-serif;
    display: inline-block;
    padding: 0 0 0 16px;
    font-size: 14px;
    text-transform: uppercase;
    color: #090909;
    line-height: 98%;
    padding-right: 10px;
  }
}
.desktop-menu {
  .menu-wrapper {
    background-color: #81766f;
    background-image: url(../assets/eye.png);
    background-size: cover;
    background-position: center;
    position: relative;
    height: 35vh;
    width: calc(100vw - 20px);
    max-width: 1800px;
    margin: 0 auto;
    .menu-img {
      z-index: 1;
      top: 0;
      height: 35vh;
    }
    .text-hero {
      max-width: 1800px;
      .menu-text-sm {
        position: absolute;
        top: calc(35vh - 230px);
        z-index: 2;
        text-align: left;
        font-family: "Johnson-Medium", Arial, Helvetica, sans-serif;
        font-size: 40px;
        line-height: 95%;
        color: white;
        margin-left: 20px;
      }
      .menu-text {
        position: absolute;
        top: calc(35vh - 260px);
        z-index: 2;
        margin-left: 15px;
        text-align: left;
        line-height: 125px;
        font-family: "Johnson-Medium", Arial, Helvetica, sans-serif;
        font-size: 125px;
        color: white;
      }
    }
  }
  .menu-links {
    width: calc(100vw - 20px);
    max-width: 1800px;
    z-index: 2;
    padding: 20px;
    height: calc(0.65 * (100vh - 345px));
    margin: 0 auto 0;
    display: flex;
    align-content: flex-start;
    background-color: #e8e6e3;
    .menu-link-wrapper {
      padding: 0 8px;
      display: inline-block;
      .menu-link {
        cursor: pointer;
        margin: 0;
        height: calc(0.65 * (100vh - 405px));
        background-color: white;
        border-radius: 10px;
        color: black;
        text-align: center;
        padding-top: 20%;
        position: relative;
        .menu-link-text {
          font-family: "JohnsonText-Medium", Arial, Helvetica, sans-serif;
          padding: 0 15px;
          font-size: 21px;
          color: #090909;
          line-height: 115%;
        }
        .menu-link-img {
          position: absolute;
          bottom: 100px;
          left: calc(50% - 50px);
          height: 100px;
          width: 100px;
          line-height: 28px;
          text-align: center;
        }
        .menu-link-icon {
          position: absolute;
          bottom: 32px;
          left: calc(50% - 16px);
          background-color: #eb1600;
          height: 32px;
          width: 32px;
          line-height: 28px;
          text-align: center;
          color: white;
          border-radius: 16px;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .desktop-menu {
    .menu-wrapper .text-hero {
      .menu-text-sm {
        top: calc(35vh - 160px);
        font-size: 25px;
      }
      .menu-text {
        top: calc(35vh - 130px);
        font-size: 80px;
      }
    }
    .menu-links .menu-link-wrapper .menu-link .menu-link-text {
      font-size: 16px;
    }
  }
  .menu-logo-desktop {
    height: 100px;
    .v-image {
      max-width: 300px;
      max-height: 150px;
    }
  }
  .header-menu-link {
    .menu-link-icon {
      width: 20px;
    }
    .menu-link-text {
      font-size: 12px;
    }
  }
}
@media (min-width: 1280px) and (max-height: 950px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 64px;
  }
}
@media (min-width: 1280px) and (max-height: 840px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 35px;
    .menu-link-img {
      margin-bottom: 16px;
      height: 90px;
      width: 90px;
      left: calc(50% - 45px);
    }
  }
}
@media (min-width: 1280px) and (max-height: 775px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 25px;
    .menu-link-img {
      height: 64px;
      width: 64px;
      left: calc(50% - 32px);
      margin-bottom: 32px;
    }
  }
}
@media (min-width: 1280px) and (max-height: 695px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 32px;
    .menu-link-img {
      display: none;
    }
  }
}
@media (min-width: 1280px) and (max-height: 595px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 32px;
    .menu-link-img {
      display: none;
    }
    .menu-link-icon {
      display: none;
    }
  }
}
@media (max-width: 1279px) and (max-height: 1050px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 64px;
  }
}
@media (max-width: 1279px) and (max-height: 940px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 35px;
    .menu-link-img {
      margin-bottom: 16px;
      height: 90px;
      width: 90px;
      left: calc(50% - 45px);
    }
  }
}
@media (max-width: 1279px) and (max-height: 875px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 25px;
    .menu-link-img {
      height: 64px;
      width: 64px;
      left: calc(50% - 32px);
      margin-bottom: 32px;
    }
  }
}
@media (max-width: 1279px) and (max-height: 795px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 32px;
    .menu-link-img {
      display: none;
    }
  }
}
@media (max-width: 1279px) and (max-height: 695px) {
  .desktop-menu .menu-links .menu-link-wrapper .menu-link {
    padding-top: 32px;
    .menu-link-img {
      display: none;
    }
    .menu-link-icon {
      display: none;
    }
  }
}
</style>
